import { action, configure, makeObservable, observable } from "mobx";
import React from "react";
import { API_HOST } from "../variables";

configure({
    useProxies: "never"
});

export class NotificationStore {
    @observable
    isShow: boolean = false;

    @observable
    notify: Notify | null = "info";

    @observable
    content: string = "";

    constructor() {
        makeObservable(this);
    }

    @action
    show(notify: Notify, content: string) {
        this.isShow = true;
        this.notify = notify;
        this.content = content;
    }

    @action
    error(error: any) {
        this.isShow = true;
        this.notify = "error";

        if ((error && error.status === 401) || (error && error.response && error.response.status === 401)) {
            window.location.href = `${API_HOST}/nlu/v1/auth/login?client_redirect_uri=${document.URL}`;
        }

        if (error.hasOwnProperty("msg") && error.msg) {
            this.content = error.msg;
        } else if (error.response && error.response.data && error.response.data.hasOwnProperty("msg") && error.response.data.msg) {
            this.content = error.response.data.msg;
        } else if (error.data && error.data.msg) {
            this.content = error.data.msg;
        } else if (error.response && error.response.data && error.response.data.hasOwnProperty("detail") && error.response.data.detail) {
            this.content = error.response.data.detail;
        } else if (error.data && error.data.detail) {
            this.content = error.data.detail;
        } else {
            this.content = "ERROR!!";
        }
    }

    @action
    close() {
        this.isShow = false;
        this.notify = null;
        this.content = "";
    }
}

export type Notify = "info" | "success" | "warning" | "error";

const context = React.createContext(new NotificationStore());
export const useNotification = () => React.useContext(context);
