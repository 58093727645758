import React, { Suspense, useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import * as Layout from "@42maru/web-starter";
import Cookies from "js-cookie";
import routes from "./routes";
import { compile, pathToRegexp } from "path-to-regexp";
import Preloader from "./component/Preloader";
import preloaderStore from "./store/PreloaderStore";
import { observer } from "mobx-react-lite";
import Dialog from "./component/Dialog";
import Notification from "./component/Notification";
import authStore from "./store/AuthStore";
import ReactGA from "react-ga";
import WebChatApp from "./WebChatApp";
import { useIntl } from "react-intl";
import socketStore from "./store/WebSocketStore";
import FullLayout from "./component/layout/FullLayout";
import qs from "query-string";
import { configure } from "mobx";
import scenarioStore from "./store/ScenarioStore";
import useMetaTags from "react-metatags-hook";
import { truncate } from "lodash";
import logoImage from "./static/images/logo.png";
import { AppSwitcher, ClientEnvironment } from "@42maru/keeper-web-tool";
import { Dropdown } from "react-bootstrap";
import { getSignedUsers } from "./util/SignedUsersCookie";
import {
    DISABLE_GA,
    API_HOST,
    GA_TRACKING_KEY,
    KEEPER_HOST,
    KEEPER_API_HOST,
    KEEPER_CLIENT_ID,
    KEEPER_ENVIRONMENT
} from "./variables";

configure({
    useProxies: "never"
});

const SCENARIO_PATH_REGEX = pathToRegexp("/scenarios/:scenarioId/(.*)?");

if (!DISABLE_GA) {
    GA_TRACKING_KEY && ReactGA.initialize(GA_TRACKING_KEY);
}

const App: React.FC<RouteComponentProps> = observer(({location, history}) => {
    const intl = useIntl();
    const tokens = SCENARIO_PATH_REGEX.exec(location.pathname);
    const scenarioId = tokens ? tokens[1] : "";
    const redirectUri = Cookies.get("redirect_uri");


    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    useEffect(() => {
        if (!/^(\/webchat\/[0-9]*)/.test(location.pathname)) {
            authStore.getLoginUser().then(() => {
                if (scenarioId && authStore.loginUser) {
                    socketStore.connectSocket(scenarioId, authStore.loginUser, {transports: ["websocket"]});
                }
                if (authStore.loginUser && authStore.loginUser.user_id && authStore.loginUser.user_id.endsWith("@client")) {
                    window.location.href = `${API_HOST}/auth/login?client_redirect_uri=${document.URL}`;
                }
            }).catch((e) => {
                console.error(e);
                if (e.response && e.response.status !== 401) {
                    console.error(e);
                } else if ((e.response && e.response.status === 401) || !e.response) {
                    window.location.href = `${API_HOST}/auth/login?client_redirect_uri=${document.URL}`;
                }
            });
        }


        return () => {
            socketStore.clear();
        };
    }, [scenarioId, location.pathname]);

    useEffect(() => {
        if (scenarioId) {
            scenarioStore.loadPermissions(scenarioId);
            scenarioStore.findForMenu();
        }
    }, [scenarioId]);

    const signedUsers = authStore.loginUser ? getSignedUsers().filter((signedUser: any) => signedUser.username !== authStore.loginUser?.username) : [];
    const selectedScenario = scenarioStore.scenarios.find((scenario) => scenario.id === (scenarioId && scenarioId));
    const scenarioName = (selectedScenario && selectedScenario.name) || "-";
    const selectedScenarioName = truncate(scenarioName, {
        length: 24
    });
    const currentLocation = window.location.pathname;
    const titlePath = (currentLocation && currentLocation.substring(currentLocation.lastIndexOf("/") + 1)) || "";

    useMetaTags(
        {
            title: `[CM>${titlePath}] ${scenarioName}`,
            charset: "utf8"
        },
        [scenarioName, titlePath]
    );

    if (location.pathname === "/") {
        return <Redirect to={"/scenarios"}/>;
    }

    if (/^(\/webchat\/[0-9]*)/.test(location.pathname)) {
        const scenarioId = location.pathname.replace("/webchat/", "");
        const queryStringParams = qs.parse(location.search);
        return (
            <WebChatApp scenarioId={scenarioId || "error"} params={queryStringParams}
                        conversationId={queryStringParams.conversationId}/>
        );
    }

    if (authStore.isLogin && redirectUri) {
        Cookies.remove("redirect_uri");
        if (redirectUri !== location.pathname) {
            return <Redirect to={redirectUri}/>;
        }
    }

    if (!authStore.initialized) return null;

    let permissions: string[] = [];
    if (scenarioId) {
        permissions = scenarioStore.permissions[scenarioId] || [];
    }

    const permissionNames = (permissions || []).map((value) => {
        return value.replace("view:", "");
    });

    const menuContent = [
        {
            icon: "dripicons-graph-bar",
            label: intl.formatMessage({id: "i0004"}),
            to: "/scenarios/:scenarioId/analytics"
        },
        {
            icon: "dripicons-list",
            label: intl.formatMessage({id: "i0005"}),
            to: "/scenarios/:scenarioId/editor"
        },
        {
            icon: "dripicons-view-apps",
            label: intl.formatMessage({id: "i0007"}),
            to: "/scenarios/:scenarioId/templates"
        },
        {
            icon: "dripicons-network-3",
            label: intl.formatMessage({id: "i0006"}),
            to: "/scenarios/:scenarioId/flows"
        },
        {
            icon: "dripicons-gear",
            label: intl.formatMessage({id: "i0010"}),
            to: "/scenarios/:scenarioId/settings"
        },
        {
            icon: "dripicons-scale",
            label: intl.formatMessage({id: "i0009"}),
            to: "/scenarios/:scenarioId/integrations"
        },
        {
            icon: "dripicons-checkmark",
            label: intl.formatMessage({id: "i0120"}),
            to: "/scenarios/:scenarioId/bulk_test"
        },
        {
            icon: "dripicons-cloud-upload",
            label: intl.formatMessage({id: "i0215"}),
            to: "/scenarios/:scenarioId/scenario_deploy"
        },
        {
            icon: "dripicons-conversation",
            label: intl.formatMessage({id: "i0008"}),
            to: "/scenarios/:scenarioId/conversations"
        },
        {
            icon: "dripicons-blog",
            label: intl.formatMessage({id: "i0214"}),
            to: "/scenarios/:scenarioId/scenario_history"
        },
        {
            icon: "dripicons-media-shuffle",
            label: intl.formatMessage({id: "i10000"}),
            to: "/scenarios/:scenarioId/assistant/condition"
        }
    ];

    const hassAllPermission = permissionNames.includes("*");
    let filteredMenuContent: any[] = [];

    if (hassAllPermission) {
        filteredMenuContent = menuContent;
    } else {
        filteredMenuContent = menuContent.filter((value) => {
            const lastIndex = value.to.lastIndexOf("/");
            const pathString = value.to.substring(lastIndex + 1, value.to.length);
            return permissionNames.includes(pathString);
        });
    }

    const pathName = window.location.pathname;
    const compiledContent = filteredMenuContent.map((menuInfo) => {
        if (menuInfo.to) {
            try {
                return {
                    ...menuInfo,
                    to: compile(menuInfo.to)({scenarioId: scenarioId})
                };
            } catch {
                return menuInfo;
            }
        } else {
            return menuInfo;
        }
    });

    const onClickLogout = () => {
        authStore.logout().then(() => {
            window.location.reload();
        });
    };

    const hasMenu =
        routes.filter((value) => {
            if (value.path) {
                try {
                    return location.pathname === compile(value.path)({scenarioId: scenarioId}) && value.hasMenu;
                } catch {
                    return false;
                }
            } else {
                return false;
            }
        }).length > 0;

    const matchedPermission = permissionNames.filter((permission) => pathName.includes(permission));
    const renderContent = () => {
        if (
            !hassAllPermission &&
            scenarioId &&
            !scenarioStore.isPendingGetPermissions &&
            (permissionNames.length === 0 || (permissionNames.length > 0 && matchedPermission.length === 0))
        ) {
            return (
                <Layout.Wrapper>
                    <FullLayout>
                        <Layout.ContentTitle title="Forbidden"/>
                        <div className="align-content-center d-flex">
                            <div>{intl.formatMessage({id: "i0565"})}</div>
                        </div>
                    </FullLayout>
                </Layout.Wrapper>
            );
        }

        if (!authStore.hasAccessAllPermission) {
            let districtedPath =
                location.pathname.endsWith("settings") ||
                location.pathname.endsWith("flows") ||
                location.pathname.endsWith("integrations") ||
                location.pathname.endsWith("bulk_test");

            if (districtedPath) {
                return (
                    <Layout.Wrapper>
                        <FullLayout>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%"
                            }}>
                                <div style={{height: "100%"}}>데모용 계정으로는 사용하실 수 없습니다.</div>
                            </div>
                        </FullLayout>
                    </Layout.Wrapper>
                );
            }
        }

        return (
            <Switch>
                {routes.map((route, index) => (
                    <Route key={index} path={route.path} exact={route.exact} component={route.component}/>
                ))}
            </Switch>
        );
    };
    return (
        <Layout.Wrapper>
            {hasMenu && (
                <Layout.SideMenu>
                    <Layout.Brand homeUrl={"/"} logoImage={logoImage}/>
                    <div
                        style={{
                            backgroundColor: "rgba(0,0,0,0.12)",
                            color: "white",
                            textAlign: "center",
                            fontSize: "16px",
                            padding: "10px"
                        }}
                    >
                        <Dropdown>
                            <Dropdown.Toggle as="a" id="scenario-name">
                                {selectedScenarioName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {scenarioStore.scenarios.map((scenario) => {
                                    return (
                                        <Dropdown.Item
                                            style={{
                                                padding: "4px 8px",
                                                backgroundColor:
                                                    scenario.id === (selectedScenario && selectedScenario.id)
                                                        ? "var(--color-selected-item-light)"
                                                        : "white"
                                            }}
                                            key={`item_${scenario.id}`}
                                            as="button"
                                            onClick={() => {
                                                const path = history.location.pathname;
                                                history.push(path.replace(/scenarios\/\w+/, `scenarios/${scenario.id}`));
                                            }}
                                        >
                                            <div>{truncate(`[${scenario.id}] ${scenario.name}`, {length: 26})}</div>
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {compiledContent && compiledContent.length > 0 &&
                        <Layout.Menu content={compiledContent} path={location.pathname}/>}
                    {authStore.loginUser && (
                        <AppSwitcher
                            keeperHost={KEEPER_HOST as string}
                            keeperAPIHost={KEEPER_API_HOST as string}
                            clientId={`${KEEPER_CLIENT_ID}`}
                            user={authStore.loginUser}
                            signedUsers={signedUsers}
                            resignUrl={`${API_HOST}/auth/resign`}
                            environment={KEEPER_ENVIRONMENT as ClientEnvironment}
                            onClickLogout={onClickLogout}
                        />
                    )}
                </Layout.SideMenu>
            )}
            <Suspense fallback={<div>Loading...</div>}>{renderContent()}</Suspense>
            <Preloader loading={preloaderStore.loading}/>
            <Dialog/>
            <Notification/>
        </Layout.Wrapper>
    );
});

export default withRouter(App);
