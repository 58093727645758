import { action, computed, makeObservable, observable, runInAction } from "mobx";
import * as accountApi from "../api/accountApi";
import * as authorizationApi from "../api/authorizationApi";
import { Account } from "../model/Account";
import { configure } from "mobx";

configure({
    useProxies: "never"
});

class AuthStore {
    @observable initialized: boolean = false;
    @observable loginUser: Account | null = null;
    @observable changed = null;
    @observable hasAccessAllPermission = false;

    constructor() {
        makeObservable(this);
    }

    @computed
    get isLogin() {
        return (this.loginUser && this.loginUser.email && true) || false;
    }

    @action
    async getLoginUser() {
        try {
            const response = await accountApi.getLoginUser();
            await this.checkAuthorization();
            runInAction(() => {
                this.loginUser = response.data;
                this.initialized = true;
            });
            return this.loginUser;
        } catch (e) {
            runInAction(() => {
                this.initialized = true;
            });
            return await Promise.reject(e);
        }
    }

    // @action login = async (email: string, password: string, remember: boolean) => {
    //     try {
    //         await login({ email: email, password: password, remember: remember });
    //         return true;
    //     } catch (e) {
    //         return await Promise.reject(e);
    //     }
    // };

    @action logout = async () => {
        try {
            await accountApi.logout();
        } catch (e) {
            return await Promise.reject(e);
        }
    };

    @action checkAuthorization = async () => {
        authorizationApi
            .checkAuthorization()
            .then(
                action((response) => {
                    this.hasAccessAllPermission = true;
                })
            )
            .catch(
                action((error) => {
                    this.hasAccessAllPermission = false;
                })
            );
    };
}

const store = new AuthStore();

export default store;
