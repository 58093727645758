import axios from "axios";
import { API_HOST } from "../variables";


// export const login = (accountInfo: object) => {
//     return axios
//         .post(`${API_HOST}/auth/login`, accountInfo)
//         .then((response) => Promise.resolve(response.data))
//         .catch((error) => Promise.reject(error));
// };

export const getLoginUser = () => {
    return axios
        .get(`${API_HOST}/auth/me`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const logout = () => {
    return axios
        .post(`${API_HOST}/auth/logout`)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => Promise.reject(error));
};
