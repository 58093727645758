import React from "react";
import { Button, Modal } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import store, { DialogType } from "../store/DialogStore";
import { useIntl } from "react-intl";

const Dialog: React.FC = observer(() => {
    const intl = useIntl();
    const onClickCancelBtn = () => {
        store.cancel();
    };

    const onClickConfirmBtn = () => {
        store.confirm();
    };

    const onClickCustomBtn = () => {
        store.customClick();
    }

    let icon = "";
    if (store.type === DialogType.INFO) {
        icon = "dripicons-information";
    } else if (store.type === DialogType.SUCCESS) {
        icon = "dripicons-checkmark";
    } else if (store.type === DialogType.WARNING) {
        icon = "dripicons-warning";
    } else if (store.type === DialogType.DANGER) {
        icon = "dripicons-wrong";
    }

    return (
        <Modal centered show={store.show} size={store.size} style={{ border: "1px" }} onHide={() => {}}>
            {store.title && (
                <Modal.Header className={`modal-colored-header bg-${store.type}`}>
                    <Modal.Title as={"h5"}>{store.title}</Modal.Title>
                </Modal.Header>
            )}

            <Modal.Body>
                <div className="text-center">
                    <i className={`${icon} h1 text-${store.type}`} />
                    <p className="mt-2" style={{ whiteSpace: "pre-wrap" }}>
                        {store.message}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"light"} onClick={onClickCancelBtn}>
                    {store.cancelText || intl.formatMessage({ id: "i0002" })}
                </Button>
                {store.customText && (
                    <Button variant={"light"} onClick={onClickCustomBtn}>
                        {store.customText}
                    </Button>
                )}
                <Button variant={"danger"} onClick={onClickConfirmBtn}>
                    {store.confirmText || intl.formatMessage({ id: "i0003" })}
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default Dialog;
