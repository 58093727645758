import { makeObservable, observable, configure, runInAction } from "mobx";

configure({
    useProxies: "never"
});

export interface FacebookSdkProps {
    init: (params: any) => void;
    api: (path: string, method: string, params?: any, callback?: any) => void;
    ui: (params: any, callback?: any) => void;
    getLoginStatus: (callback?: any) => void;
    login: (callback?: any, params?: any) => void;
    XFBML: any;
}

export class FacebookSdkStore {
    @observable facebookSdk: FacebookSdkProps = (window as any).FB;
    @observable initialized: boolean = false;
    @observable isLogin: boolean = false;
    @observable accessToken?: string;
    facebookAppId?: string;
    facebookAppSecret?: string;

    constructor() {
        makeObservable(this);
    }

    initialize(facebookAppId: string, facebookAppSecret: string) {
        this.facebookAppId = facebookAppId;
        this.facebookAppSecret = facebookAppSecret;
        const _this = this;
        return new Promise((resolve, reject) => {
            (window as any).fbAsyncInit = function () {
                (window as any).FB.init({
                    appId: facebookAppId,
                    cookie: true,
                    xfbml: true,
                    version: "v11.0"
                });
                runInAction(() => {
                    _this.facebookSdk = (window as any).FB;
                    _this.initialized = true;
                });
                _this.getLoginStatus();
                resolve();
            };

            // load facebook sdk script
            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                (js as any).src = "https://connect.facebook.net/ko_KR/sdk.js";
                fjs.parentNode!.insertBefore(js, fjs);
            })(document, "script", "facebook-jssdk");
        });
    }

    private api(path: string, method: string, params?: any) {
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject();
            }
            this.facebookSdk!.api(path, method, params, (response: any) => {
                if (!response) {
                    reject();
                } else if (response.error) {
                    reject(response.error);
                } else {
                    resolve(response);
                }
            });
        });
    }

    private ui(method: string) {
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject();
            }
            this.facebookSdk!.ui(
                {
                    method: method,
                    redirect_uri: window.location.href
                },
                (response: any) => {
                    if (!response) {
                        reject();
                    } else if (response.error) {
                        reject(response.error);
                    } else {
                        resolve(response);
                    }
                }
            );
        });
    }

    getLoginStatus() {
        const _this = this;
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject();
            }
            this.facebookSdk!.getLoginStatus((response: any) => {
                if (!response) {
                    reject();
                } else if (response.error) {
                    reject(response.error);
                } else {
                    if (response.authResponse) {
                        _this.getLongLiveUserToken(response.authResponse.accessToken).then((res: any) => {
                            runInAction(() => {
                                _this.accessToken = res.accessToken;
                                _this.isLogin = true;
                            });
                        });
                    }
                    resolve(response);
                }
            });
        });
    }

    async getMe() {
        return this.api("/me", "get");
    }

    async getPageList() {
        try {
            await this.getLoginStatus();
            return this.api(`/me/accounts`, "get", {
                access_token: this.accessToken,
                fields: "name,about,picture,id,access_token,instagram_business_account{id,username,profile_picture_url}"
            });
        } catch (e) {
            return e;
        }
    }

    async getLongLiveUserToken(accessToken: string) {
        return this.api("/oauth/access_token", "get", {
            grant_type: "fb_exchange_token",
            client_id: this.facebookAppId,
            client_secret: this.facebookAppSecret,
            fb_exchange_token: accessToken
        });
    }

    pageTab() {
        return this.ui("pagetab");
    }

    getInstagramBusinessAccountId(pageId: string) {
        return this.api(`/${pageId}`, "get", { fields: "instagram_business_account" });
    }

    parseXfbml() {
        this.facebookSdk!.XFBML.parse();
    }

    subscribe(pageId: string, pageAccessToken: string) {
        return this.api(`/${pageId}/subscribed_apps`, "post", {
            access_token: pageAccessToken,
            subscribed_fields: "messages, messaging_postbacks, message_reads, message_echoes, messaging_handovers, standby"
        });
    }

    login() {
        const _this = this;
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject();
            }
            this.facebookSdk!.login(
                (response: any) => {
                    if (!response) {
                        reject();
                    } else if (response.error) {
                        reject(response.error);
                    } else {
                        if (response.authResponse) {
                            _this.getLongLiveUserToken(response.authResponse.accessToken).then((res: any) => {
                                runInAction(() => {
                                    _this.accessToken = res.access_token;
                                    _this.isLogin = true;
                                });
                            });
                        }
                        resolve(response);
                    }
                },
                { scope: "email,public_profile,instagram_basic,pages_show_list,pages_messaging,instagram_manage_messages,pages_manage_metadata" }
            );
        });
    }

    relogin() {
        const _this = this;
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                reject();
            }
            this.facebookSdk!.login(
                (response: any) => {
                    if (!response) {
                        reject();
                    } else if (response.error) {
                        reject(response.error);
                    } else {
                        if (response.authResponse) {
                            _this.getLongLiveUserToken(response.authResponse.accessToken).then((res: any) => {
                                runInAction(() => {
                                    _this.accessToken = res.access_token;
                                    _this.isLogin = true;
                                });
                            });
                        }
                        resolve(response);
                    }
                },
                { scope: "email,public_profile,instagram_basic,pages_show_list,pages_messaging,instagram_manage_messages,pages_manage_metadata", auth_type: "rerequest" }
            );
        });
    }
}

const store = new FacebookSdkStore();
export default store;
