import React from 'react';
import classnames from 'classnames';


interface PreloaderProps {
    loading: boolean;
}

const Preloader:React.FC<PreloaderProps> = ({loading}) => {

    return (
        <div className={classnames('loader-wrapper', {'d-none': !loading})}>
            <div className="loader">
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-2" role="status"/>
                </div>
            </div>
        </div>
    );
};

export default Preloader;