import React from "react";
import * as Layout from "@42maru/web-starter";

const FullLayout: React.FC = ({ children }) => {
    return (
        <>
            <Layout.ContentPage>
                <Layout.Content>
                    {/*<Layout.NavBar>*/}
                    {/*</Layout.NavBar>*/}
                    <Layout.ContentContainer>{children}</Layout.ContentContainer>
                </Layout.Content>

                <Layout.Footer
                    copyright={"Copyright © 2019 42MARU, All rights reserved."}
                    // about={"/about"}
                    // support={"/support"}
                    // contactUs={"/contactus"}
                />
            </Layout.ContentPage>
        </>
    );
};

export default FullLayout;
