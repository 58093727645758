import { action, makeObservable, observable, configure } from "mobx";

configure({
    useProxies: "never"
});
type SupportedLocale = "ko" | "en";

class LocaleStore {
    @observable locale: SupportedLocale = "ko";
    constructor() {
        makeObservable(this);
        this.locale =
            (localStorage.getItem("locale") as SupportedLocale) || "ko";
    }

    @action
    setLocale(locale: SupportedLocale) {
        localStorage.setItem("locale", locale);
        this.locale = locale;
    }
}

const store = new LocaleStore();

export default store;
