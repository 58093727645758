import Cookies from "js-cookie";


export const getSignedUsers = () => {
    const signedUsersToken = Cookies.get('SU_TOKEN');
    if (signedUsersToken) {
        const payload = signedUsersToken.split('.')[1]
        const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload).all;
    }
    return [];
};
