import React from "react";

export const Scenarios = React.lazy(() => import("./scenario/Scenarios"));
export const ScenarioEditor = React.lazy(() => import("./scenario/ScenarioEditor"));
export const ScenarioHistory = React.lazy(() => import("./scenario/ScenarioHistory"));
export const ScenarioDeploy = React.lazy(() => import("./scenario/ScenarioDeploy"));
export const ScenarioBulkTest = React.lazy(() => import("./scenario/ScenarioBulkTest"));
export const SceneTemplateEditor = React.lazy(() => import("./scenario/SceneTemplateEditor"));
export const Settings = React.lazy(() => import("./scenario/ScenarioSettings"));
export const ScenarioFlow = React.lazy(() => import("./scenario/ScenarioFlow"));
export const MessageHistory = React.lazy(() => import("./conversation/MessageHistory"));
export const NotFound = React.lazy(() => import("./error/NotFound"));
export const Forbidden = React.lazy(() => import("./error/Forbidden"));
export const IntegraionMain = React.lazy(() => import("./integration/IntegrationMain"));
export const WebChatIntegrationView = React.lazy(() => import("./integration/WebChatIntegrationView"));
export const KakaoTalkIntegrationView = React.lazy(() => import("./integration/KakaoTalkIntegrationView"));
export const NaverTalkIntegrationView = React.lazy(() => import("./integration/NaverTalkIntegrationView"));
export const FacebookIntegrationView = React.lazy(() => import("./integration/FacebookIntegrationView"));
export const InstagramIntegrationView = React.lazy(() => import("./integration/InstagramIntegrationView"));
export const TeamUpIntegrationView = React.lazy(() => import("./integration/TeamUpIntegrationView"));
export const RCSIntegrationView = React.lazy(() => import("./integration/RCSIntegrationView"));
export const LineIntegrationView = React.lazy(()=>import("./integration/LineIntegrationView"));
export const AzureIntegrationView = React.lazy(()=>import("./integration/AzureIntegrationView"));
export const SlackIntegrationView = React.lazy(()=>import("./integration/SlackIntegrationView"));
export const JandiIntegrationView = React.lazy(()=>import("./integration/JandiIntegrationView"));
export const EnomixIntegrationView = React.lazy(() => import("./integration/EnomixIntegrationView"));
export const UiPathIntegrationView = React.lazy(() => import("./integration/UiPathIntegrationView"));
export const ZendeskIntegrationView = React.lazy(() => import("./integration/ZendeskIntegrationView"));
export const SwingChatMessageIntegrationView = React.lazy(() => import("./integration/SwingChatMessageIntegrationView"));
export const LiveChatIntegrationView = React.lazy(() => import("./integration/LiveChatIntegrationView"));
export const AnalyticsView = React.lazy(() => import("./scenario/AnalyticsView"));
export const ConditionAssistantView = React.lazy(() => import("./assistant/ConditionAssistantView"))
