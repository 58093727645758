import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import classNames from 'classnames';
import {Fade} from 'react-bootstrap';
import {Notify, useNotification} from '../hooks/useNotification';

const Notification: React.FC = observer(() => {

    const notification = useNotification();
    const { notify, isShow, content } = notification;

    return (
        <>
            <div className={classNames('jq-toast-wrap top-right')}>
                {isShow && <NotificationItem notify={notify} content={content} />}
            </div>
        </>
    );
});

const NotificationItem: React.FC<NotificationItemProps> = ({notify, content}) => {
    const notification = useNotification();
    const [load, setLoad] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setLoad(true);
        }, 50);

        setTimeout(() => {
            setLoad(false);
            setTimeout(() => {
                notification.close();
            }, 50);
        }, 2700);
    }, [notification]);

    const onClickCloseBtn = (e: React.MouseEvent) => {
        e.preventDefault();
        notification.close();
    };

    return (
        <Fade in={load}>
            <div className={`jq-toast-single jq-has-icon jq-icon-${notify} text-left`}>
                <span className={classNames('jq-toast-loader', {'jq-toast-loaded': load})} style={{WebkitTransition: 'width 2.6s ease-in', OTransition: 'width 2.6s ease-in', transition: 'width 2.6s ease-in', backgroundColor: 'rgba(0,0,0,0.2)'}} />

                <span className="close-jq-toast-single" onClick={onClickCloseBtn}>×</span>
                <h2 className="jq-toast-heading">{notify?.toUpperCase()}</h2>
                {content}
            </div>
        </Fade>
    );
};

type NotificationItemProps = {
    notify: Notify | null;
    content: string;
};

export default Notification;