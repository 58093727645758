import { action, makeObservable, observable } from "mobx";
import { configure } from "mobx";

configure({
    useProxies: "never"
});
export enum DialogType {
    PRIMARY = "primary",
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
    DANGER = "danger"
}

interface DialogParams {
    type: DialogType;
    size?: "sm" | "lg" | "xl";
    title?: string;
    message: string;
    customText?: string;
    cancelText?: string;
    confirmText?: string;
    onClickCustom?: () => void;
    onCancel?: () => void;
    onConfirm?: () => void;
}

class DialogStore {
    @observable show: boolean = false;
    @observable size: "sm" | "lg" | "xl" = "sm";
    @observable title?: string;
    @observable message: string = "";
    @observable type: DialogType = DialogType.PRIMARY;
    @observable customText?: string;
    @observable cancelText?: string;
    @observable confirmText?: string;
    @observable onClickCustom?: () => void;
    @observable onCancel?: () => void;
    @observable onConfirm?: () => void;

    constructor() {
        makeObservable(this);
    }

    @action
    open = (params: DialogParams) => {
        this.show = true;
        this.size = params.size || "sm";
        this.type = params.type;
        this.title = params.title;
        this.message = params.message;
        this.customText = params.customText;
        this.cancelText = params.cancelText;
        this.confirmText = params.confirmText;
        this.onClickCustom = params.onClickCustom;
        this.onCancel = params.onCancel;
        this.onConfirm = params.onConfirm;
    };

    @action
    customClick = () => {
        this.onClickCustom && this.onClickCustom();
        this._clear();
    };

    @action
    cancel = () => {
        this.onCancel && this.onCancel();

        this._clear();
    };

    @action
    confirm = () => {
        this.onConfirm && this.onConfirm();

        this._clear();
    };

    _clear = () => {
        this.show = false;
        // this.title = undefined;
        // this.message = '';
        // this.type = DialogType.INFO;
        // this.cancelText = undefined;
        // this.confirmText = undefined;
        this.onClickCustom = undefined;
        this.onCancel = undefined;
        this.onConfirm = undefined;
    };
}

const store = new DialogStore();

export default store;
