import {action, makeObservable, observable} from 'mobx';
import { configure } from "mobx";

configure({
    useProxies: "never"
});
class PreloaderStore {
    @observable loading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action on = () => {
        this.loading = true;
    };

    @action off = () => {
        this.loading = false;
    };
}

const store = new PreloaderStore();

export default store;
