export const getRandomId = (length: number) => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const getClientId = () => {
    const savedUserKey = localStorage.getItem("conversation_maker_client_key");

    if (savedUserKey) {
        return savedUserKey;
    }

    const randomHashValue = getRandomId(32);
    localStorage.setItem("conversation_maker_client_key", randomHashValue);
    return randomHashValue;
};

export const isUrl = (url: string) => {
    if (url.includes(" ")) {
        return false;
    }
    const expression = /([a-zA-Z]{2,20}):\/\/([\w_-]+(?:(?:\.[\w_-]+)?))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi;
    const regex = new RegExp(expression);
    return regex.test(url);
};
