import { action, makeObservable, observable } from "mobx";
import { ConversationMakerSocket } from "../util/ConversationMakerSocket";
import { getClientId } from "../util/stringUtil";
import { configure } from "mobx";
import { SOCKET_HOST, SOCKET_PATH } from "../variables";
import { Account } from "../model/Account";

configure({
    useProxies: "never"
});
export interface WebSocketClient {
    client_id: string;
    user_id: number;
    user_name: string;
    user_email: string;
}

type WebScoketEvents =
    | "client_connected"
    | "client_disconnected"
    | "message_receive"
    | "message_send"
    | "messaging_user_create"
    | "bulk_test_started"
    | "bulk_test_progress"
    | "bulk_test_finished"
    | "bulk_test_canceled"
    | "scenario_import_finished"
    | "scenario_import_progress"
    | "scenario_import_started"
    | "scenario_import_error";

interface EventFunctions {
    [key: string]: any[];
}
class WebSocketStore {
    static _instance: WebSocketStore;
    @observable scenarioId: string = "";
    @observable connected: boolean = false;
    @observable clients: WebSocketClient[] = [];

    socketInitialized: boolean = false;
    socket?: ConversationMakerSocket;
    eventFunctions: EventFunctions = {
        client_connected: [],
        client_disconnected: [],
        message_receive: [],
        message_send: [],
        messaging_user_create: [],
        scenario_import_started: [],
        scenario_import_progress: [],
        scenario_import_finished: [],
        scenario_import_error: [],
        bulk_test_started: [],
        bulk_test_progress: [],
        bulk_test_finished: [],
        bulk_test_canceled: []
    };

    constructor() {
        makeObservable(this);
    }

    static getInstance = () => {
        if (WebSocketStore._instance == null) {
            WebSocketStore._instance = new WebSocketStore();
        }
        return WebSocketStore._instance;
    };

    @action
    connectSocket = (scenarioId: string, loginUser: Account, opts?: SocketIOClient.ConnectOpts) => {
        this.scenarioId = scenarioId;
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
        }

        if (!this.socket) {
            this.socket = new ConversationMakerSocket(`${SOCKET_HOST}`, `${SOCKET_PATH}`, opts);
            this.socket.connect(
                {
                    client_id: getClientId(),
                    scenario_id: scenarioId,
                    email: loginUser.email,
                    name: loginUser.name,
                    uid: loginUser.user_id
                },
                () => {
                    this.connected = true;
                }
            );

            this.socket.on("disconnected", () => {
                this.connected = false;
            });

            this.socket.on(
                "client_connected",
                action((response: any) => {
                    this.clients = response.data.clients;
                    this.eventFunctions["client_connected"].forEach((func) => func(response.data));
                })
            );

            this.socket.on(
                "client_disconnected",
                action((response: any) => {
                    this.clients = response.data.clients;
                    this.eventFunctions["client_disconnected"].forEach((func) => func(response.data));
                })
            );

            this.socket.on("message_receive", (response: any) => {
                this.eventFunctions["message_receive"].forEach((func) => func(response.data));
            });

            this.socket.on("message_send", (response: any) => {
                this.eventFunctions["message_send"].forEach((func) => func(response.data));
            });

            this.socket.on("messaging_user_create", (response: any) => {
                this.eventFunctions["messaging_user_create"].forEach((func) => func(response.data));
            });

            this.socket.on("scenario_import_error", (response: any) => {
                this.eventFunctions["scenario_import_error"].forEach((func) => func(response.data));
            });

            this.socket.on("scenario_import_finished", (response: any) => {
                this.eventFunctions["scenario_import_finished"].forEach((func) => func(response.data));
            });

            this.socket.on("scenario_import_started", (response: any) => {
                this.eventFunctions["scenario_import_started"].forEach((func) => func(response.data));
            });

            this.socket.on("scenario_import_progress", (response: any) => {
                this.eventFunctions["scenario_import_progress"].forEach((func) => func(response.data));
            });

            this.socket.on("bulk_test_started", (response: any) => {
                this.eventFunctions["bulk_test_started"].forEach((func) => func(response.data));
            });

            this.socket.on("bulk_test_progress", (response: any) => {
                this.eventFunctions["bulk_test_progress"].forEach((func) => func(response.data));
            });

            this.socket.on("bulk_test_finished", (response: any) => {
                this.eventFunctions["bulk_test_finished"].forEach((func) => func(response.data));
            });
            this.socket.on("bulk_test_canceled", (response: any) => {
                this.eventFunctions["bulk_test_canceled"].forEach((func) => func(response.data));
            });

            this.socketInitialized = true;
        }
    };

    sendMessage = (data: any) => {
        if (this.socket) {
            this.socket.emit("chat_consultant", data);
        }
    };

    send = (event: string, data: any) => {
        if (this.socket) {
            this.socket.emit(event, data);
        }
    };

    disconnect = () => {
        try {
            this.socket!.disconnect();
        } catch (e) {}
        this.connected = false;
    };

    addEventListener = (event: WebScoketEvents, callback: (data: any) => void) => {
        this.eventFunctions[event].push(callback);
    };

    removeEventListener = (event: WebScoketEvents, callback: (data: any) => void) => {
        const index = this.eventFunctions[event].indexOf(callback);
        if (index >= 0) {
            this.eventFunctions[event].splice(index, 1);
        }
    };

    @action
    clear = () => {
        this.scenarioId = "";
        this.disconnect();
        this.socket = undefined;
        this.socketInitialized = false;
        this.clients = [];
    };
}

const store = WebSocketStore.getInstance();

export default store;
