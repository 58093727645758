import axios from "axios";
import { Scenario } from "../model/Scenario";
import { API_HOST } from "../variables";

export const create = (name: string, description: string) => {
    return axios
        .post(`${API_HOST}/scenario`, {
            name,
            description
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const find = () => {
    return axios
        .get(`${API_HOST}/scenario`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const findPage = (page?: number, size?: number, keyword?: string) => {
    return axios
        .get(`${API_HOST}/scenario/paginate`, {
            params: {
                page: page,
                size: size,
                keyword: keyword
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const read = (scenarioId: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getScenarioTest = (scenarioId: string, page?: number, size?: number) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/bulktest`, {
            params: {
                page: page,
                size: size
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getScenarioTestCase = (scenarioId: string, scenarioTestId: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/testing/${scenarioTestId}/case`, {
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getScenarioTestResult = (scenarioId: string, scenarioTestId: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/testing/${scenarioTestId}/result`, {
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getScenarioHistory = (scenarioId: string, page?: number, size?: number) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/history`, {
            params: {
                page: page,
                size: size
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getScenarioDeploy = (scenarioId: string, page?: number, size?: number) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/deploy`, {
            params: {
                page: page,
                size: size
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getScenarioDeployDiff = (scenarioId: string, deploy_id: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/deploy/diff`, {
            params: {
                deploy_id
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(MediaStreamError));
};

export const getScenarioHistoryDiff = (scenarioId: string, history_id: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/history/diff`, {
            params: {
                history_id
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const deployScenario = (scenarioId: string, version: string, description?: string) => {
    return axios
        .post(`${API_HOST}/scenario/${scenarioId}/deploy`, {
            version,
            description
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const restoreScenarioDeploy = (scenarioId: string, deploy_id: string) => {
    return axios
        .post(`${API_HOST}/scenario/${scenarioId}/restore`, {
            deploy_id
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const rollbackScenario = (scenarioId: string, history_id: string) => {
    return axios
        .post(`${API_HOST}/scenario/${scenarioId}/rollback`, {
            history_id
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const update = (scenarioId: string, scenario: Partial<Scenario>) => {
    return axios
        .put(`${API_HOST}/scenario/${scenarioId}`, scenario)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const deleteScenario = (scenarioId: string) => {
    return axios
        .delete(`${API_HOST}/scenario/${scenarioId}`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const exportScenario = (scenarioId: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/export`, {
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

// export const exportDeployedScenario = (scenarioId: string, deployId: string) => {
//     return axios
//         .get(`${API_HOST}/scenario/${scenarioId}/deploy/${deployId}/export`, {
//             responseType: "blob",
//             headers: { "Cache-Control": "no-cache" }
//         })
//         .then((response) => Promise.resolve(response.data))
//         .catch((error) => Promise.reject(error));
// };

export const importScenario = (scenarioId: string, formData: FormData) => {
    return axios
        .post(`${API_HOST}/scenario/${scenarioId}/import`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 5 * 60 * 1000
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const bulkTestScenario = (scenarioId: string, formData: FormData) => {
    return axios
        .post(`${API_HOST}/scenario/${scenarioId}/bulktest`, formData, {
            timeout: 600000, // 10minutes
            responseType: "blob",
            headers: { "Content-Type": "multipart/form-data", "Cache-Control": "no-cache" }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getNLUAgents = () => {
    return axios
        .get(`${API_HOST}/agents`, {
            params: {
                limit: 10000,
                active: true
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getAutocompleteTokens = (agentToken: string) => {
    return axios
        .get(`${API_HOST}/agents/autocompletetokens`, {
            params: {
                agent_token: agentToken
            }
        })
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
};

export const getScenarioAutocompletes = (scenarioId: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/autocomplete`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export const getScenarioPermissions = (scenarioId: string) => {
    return axios
        .get(`${API_HOST}/scenario/${scenarioId}/permission`)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};
